.services-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #666666;
    padding: 12px 20px;
}

.services-text, .services-list-group {
    font-family: 'Open Sans', sans-serif;
    font-size: 14.5px;
    color: #000000;
    line-height: 1.8em;
    margin: 0px 0px 16px;
    padding-left: 10px;
    
   
}

.services-list-group {
    padding: 1.25rem;
  
    
}
