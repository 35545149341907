.services-header {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 34px !important;
    color: #252525 !important;
}

.line {
    color: #3483DE;
}

#card {
    border: none;
}

.card-body {
    padding: 1.5rem 1.5rem;
}

.card-footer {
    background-color: transparent;
    border-top: none;
    padding-bottom: 2.5rem;
}

.cardHeader {
    background-color: transparent;
    border: none;
}

.card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #252525;
}

.card-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
}

.btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.btn-outline-secondary {
    color: #868e96;
    background-color: transparent;
    background-image: none;
    border-color: #868e96;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}

#document-btn {
    margin-top: 28px;
}

#migration-btn {
    margin-top: 69px;
}

#web-btn {
    margin-top: 28px;
}

#mining-btn {
    margin-top: 50px;
}